import React from 'react'

const TopIcon = () => {
  return (
    <div style={{ marginTop: 5 }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="10" viewBox="0 0 20 11.818">
        <path
          d="M10,0,8.182,1.818,16.364,10,8.182,18.182,10,20,20,10Z"
          transform="translate(0 20) rotate(-90)"
        />
      </svg>
    </div>
  )
}

export default TopIcon