import React from 'react'
import styled from 'styled-components'

const Div = styled.div`
  display: grid; 
  ${({ gutter }) => `grid-column-gap: ${gutter}px`};
  ${({ templateColumns }) =>
    `grid-template-columns: ${templateColumns || 'initial'}`};
}`

export default function GridWrapper({
  children,
  col,
  gutter,
  templateColumns,
}) {
  return (
    <Div col={col} gutter={gutter} templateColumns={templateColumns}>
      {children}
    </Div>
  )
}
