import React from 'react'
import SimpleLink from './link'
import { useTranslation } from 'react-i18next'
import { germany } from '../../initial/vars'

const Manuals = product => {

  const prod  = product.product
 
    const { t } = useTranslation()
  
    return (
    
    <div className="section_content">

      { germany ?
        <>
          { prod.acf.man_1_EN && <SimpleLink url={prod.acf.man_1_EN} title={t(`${prod.acf.man_1_TITLE}`)} /> }
          { prod.acf.man_2_EN && <SimpleLink url={prod.acf.man_2_EN} title={t(`${prod.acf.man_2_TITLE}`)} /> }
          { prod.acf.man_3_EN && <SimpleLink url={prod.acf.man_3_EN} title={t(`${prod.acf.man_3_TITLE}`)} /> }
          { prod.acf.man_4_EN && <SimpleLink url={prod.acf.man_4_EN} title={t(`${prod.acf.man_4_TITLE}`)} /> }
          { prod.acf.man_5_EN && <SimpleLink url={prod.acf.man_5_EN} title={t(`${prod.acf.man_5_TITLE}`)} /> }
        </>
        :
        <>
          { prod.acf.man_1_PL && <SimpleLink url={prod.acf.man_1_PL} title={t(`${prod.acf.man_1_TITLE}`)} /> }
          { prod.acf.man_2_PL && <SimpleLink url={prod.acf.man_2_PL} title={t(`${prod.acf.man_2_TITLE}`)} /> }
          { prod.acf.man_3_PL && <SimpleLink url={prod.acf.man_3_PL} title={t(`${prod.acf.man_3_TITLE}`)} /> }
          { prod.acf.man_4_PL && <SimpleLink url={prod.acf.man_4_PL} title={t(`${prod.acf.man_4_TITLE}`)} /> }
          { prod.acf.man_5_PL && <SimpleLink url={prod.acf.man_5_PL} title={t(`${prod.acf.man_5_TITLE}`)} /> }
        </>
      }
  
    </div>

    )
  }
  
 

export default Manuals
