import React, { useState } from 'react'
import styled from 'styled-components'
import { borderGray, breakpoints } from '../../utils/theme'
import Line from './Line'
import SectionTitle from './SectionTitle'

const StyledSection = styled.div`
  padding-top: 60px;
  padding-bottom: 60px;

  @media (max-width: ${breakpoints.mobile}px) {
    padding-top:  0px;
    padding-bottom: 0px;

    > .open  {  
      border-bottom: 1px solid white; 
    }

  }
`

const Heading = styled.div`
 display: flex; 
 padding-top: 12px; 

 span {
  display: none; 
 }

  @media (max-width: ${breakpoints.mobile}px) {
    cursor: pointer; 
    font-size: 14px; 
    margin-bottom: 0;
    border-bottom: 1px solid ${borderGray};

    span { 
      display: block; 
      margin-right: 0;
      margin-left: auto;
      width: 15px;
      text-align: center;
      font-weight: 400;
    }


  }

`

const Body = styled.div`
  font-size: 20px;
  margin-top: 48px;
 
  .section_content {
    width: calc(75% - 12px);
    margin-left: auto;

    img {
      margin-left: -18.75%;
    }

    table {
      margin-top: -6px;
      margin-bottom: 36px;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.2;

      td, th {
          border-bottom: none;
          padding: 8px;
      }

      tbody tr:last-child {
          border-bottom: 1px solid #f2f2f2;
      }

      tbody tr:nth-child(odd) {
          background-color: #f2f2f2;
      }

      tbody td:first-child {
        text-align: right;
        width: 40%;
      }
    }
 
    > p {
      margin-bottom: 24px;
    }

    > ul {
      list-style: none;
      margin: 0;
      max-width: calc(66.66667% - 12px);

      > li {
          margin-bottom: 24px;
      }
    }
  }

  @media (max-width: ${breakpoints.tablet}px) {
    .section_content {
      width: 100%;
      margin-left: initial;

      > ul {
        max-width: calc(100% - 0px);
        > li {
            margin-bottom: 12px;
        }
      }
    }
  }

  @media (max-width: ${breakpoints.mobile}px) {
    font-size: 14px;
    margin-top: 0; 

    .section_content {
      table {
        font-size: 14px;
        line-height: 1.4;
        margin-bottom: 24px;
      }

      img {
        margin-left: initial;
      }

      > p {
        margin-bottom: 12px;
      }

      > ul {
        > li {
            margin-bottom: 12px;
        }
      }
    }
    
    > div {
       visibility: hidden;
       height: 0;
    }

    .open {  
      display: block;
      height: 100%;

      visibility: visible;
      padding-top: 0;
      padding-bottom: 12px;
      border-bottom: 1px solid ${borderGray};
    }
  }


`


const Section = ({ title, content, open }) => {

  const [isOpen, setOpen] = useState(open)

  return (
    <StyledSection>
      <Heading onClick={() => setOpen(!isOpen)} className={isOpen ? "open" : null}>
        <SectionTitle> {title} </SectionTitle>
        <span> {isOpen ? "–" : "+"} </span>
      </Heading>
      <Line />
      <Body>
        <div className={isOpen ? "open" : null}>
          {content}
        </div>
      </Body>
    </StyledSection>
  )
}

export default Section