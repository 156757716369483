import React, { useContext, } from 'react'
import { useTranslation } from 'react-i18next'
import { UsersContext } from '../../context'
import { isConfigurableProduct } from '../../utils/utils'

const Sku = ({ product, className }) => {
    const { createdSku } = useContext(UsersContext)
    const { t } = useTranslation()

    return (
        <span className={className}>
            {isConfigurableProduct(product) ? `${t("Wariant")}: ` :
            (product.node.sku ? `${t("Produkt")}: ` : "\u00A0")}
            {isConfigurableProduct(product) ? createdSku : product.node.sku}
        </span>
    )
}


export default Sku 