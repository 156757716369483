import React from 'react'
import styled from 'styled-components'
import { breakpoints, tychoNavy } from '../../utils/theme'
import { Swiper } from 'swiper/react'

const SwiperWrapper = styled.div`
margin-left: 32px;
margin-right: 32px;

.swiper-button-prev {
  color: ${tychoNavy}; 
  margin-left: -12px;
}
.swiper-button-next  {
  color: ${tychoNavy}; 
  margin-right: -12px;
}

  .swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after,
  .swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
    font-size: 36px;
  } 

  @media (max-width: ${breakpoints.mobile}px) {
    margin-left: -16px;
    margin-right: -16px;

    .swiper-button-next,
    .swiper-button-prev {
      display: none 
    }
  }
`

const StyledSwiper = styled(Swiper)`
  .swiper-wrapper { 
   width: max-content;
 } 
`

const CustomSwiper = ({ children }) => {

    return (
        <SwiperWrapper>
            <StyledSwiper
                className="content-kit-swiper"
                freeMode={true}
                navigation
                breakpoints={{
                    316: { slidesPerView: 2.7 },
                    516: { slidesPerView: 3.7 },
                    716: { slidesPerView: 4.7 },
                    916: { slidesPerView: 5.7 },
                    1116: { slidesPerView: 6.7 },
                    1316: { slidesPerView: 7.7 },
                    1516: { slidesPerView: 8.7 },
                }}
            >
                {children}
            </StyledSwiper>
        </SwiperWrapper >
    )
}

export default CustomSwiper
