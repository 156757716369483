import React from 'react'
import styled from 'styled-components'
import { tychoNavy } from '../../utils/theme'

const StyledSelect = styled.select`
  border: none;
  appearance: none;
  cursor: pointer;
  text-align-last: center;
  font-size: 16px;
  padding-right: 34px;
  background-color: transparent;
  opacity: 0;

  &:focus {
    outline: none;
  }
`

const Units = styled.div`
  font-size: 14px;
  position: absolute;
  top: 4px;
  z-index: -99;
  left: -5px;
`

const Traingle = styled.svg`
  fill: ${tychoNavy};
  position: absolute;
  top: 4px;
  transform: rotate(90deg) scale(0.6);
  right: 6px;
  width: 10px;
  height: 20px;
`

export default function SelectContainer({
  units,
  minValue,
  maxValue,
  value,
  setValue,
  className
}) {
  const allValues = []
  for (var i = minValue; i <= maxValue; i++) {
    allValues.push(i)
  }

  return (
    <div style={{ position: 'relative' }} className={className}>
      <StyledSelect
        onChange={e => setValue(parseInt(e.target.value))}
        value={value}
      >
        {allValues.map(val => (
          <option key={val} value={val}>
            {val}
          </option>
        ))}
      </StyledSelect>
      <Units>{value + ' ' + units}</Units>
      <Traingle>
        <path transform="rotate(45)" d="M 10 0 H 10 V 10 H 10 L 0 0" />
      </Traingle>
    </div>
  )
}
