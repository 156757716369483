import React from 'react'
import styled from 'styled-components'
import { breakpoints } from '../../utils/theme'

const StyledSectionTitle = styled.h2`
  font-size: 23.04px;
  line-height: 1.28571428571;
  font-weight: 400; 
  margin-top: -18px;
  margin-bottom: 12px;
  opacity: 0.7;
  letter-spacing: initial;

  @media (max-width: ${breakpoints.mobile}px) {
      opacity: initial;
      font-size: 14px;
      font-weight: 400; 
      line-height: 1.5;
      margin: 0 0 12px;
      padding-right: 0;
  }

`

const SectionTitle = ({ children }) => {
  return (
    <StyledSectionTitle>
      {children}
    </StyledSectionTitle>
  )
}


export default SectionTitle 