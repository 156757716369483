import React, { useState, useContext } from 'react'
import { UsersContext } from '../../context'
import styled from 'styled-components'
import { tychoNavy } from '../../utils/theme'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

const MainButtonWrapper = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const MainButtonTypoContainer = styled.button`
  font-size: 13px;
  letter-spacing: 2.8px;
  font-weight: 600;
  width: 100%;
  line-height: 16px;
  text-transform: uppercase;
  padding: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  background: none;
  border: none;
  color: white;
  background: ${tychoNavy};
  user-select: none;
  transition: opacity 0.3s;
  cursor: pointer;

  &:hover {
    opacity: 0.87;
  }
`

const AddProduct = ({ simpleProduct, readyProduct, text, style }) => {
  const [addedToCart, setAddedToCart] = useState(false)
  const { addToCartSimple, addToCart, showCart } = useContext(UsersContext)
  const { t } = useTranslation()

  return (
    <MainButtonWrapper style={style}>
      <MainButtonTypoContainer
        onClick={() => {
          showCart()
          setAddedToCart(true)
          readyProduct
            ? addToCart(readyProduct)
            : addToCartSimple(simpleProduct)
        }}
      >
        {t('Dodaj do koszyka')}
      </MainButtonTypoContainer>
      {/* Google Gtag add_to_cart */}
      <Helmet>
        {addedToCart && <script type="text/javascript">
          {`gtag('event', 'add_to_cart', {
        });
      `}
        </script>}
      </Helmet>
    </MainButtonWrapper>
  )
}

export default AddProduct
