import React, { useContext } from 'react'
import { UsersContext } from '../../context'
import Element from './element'
import KitContentsSimple from './kitContentsSimple'
import CustomSwiper from './CustomSwiper'
import { isSlidingSystemCheck, isFoldingSystemCheck, isDoubleSystemCheck } from '../../utils/utils'
import { SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Navigation } from 'swiper'
import {
  wallBracket25,
  wallBracket45,
  woodedFasciaBracket,
  spacer,
} from '../../molecules/Configurator/configurator'
import { useTranslation } from 'react-i18next'


const KitContents = product => {
  const {
    dmp,
    synchro,
    trackLength,
    mountingWall,
    doorThickness,
    aluFsc,
    aluFscSimple,
    spcr,
    woodedFasciaBrkt,
  } = useContext(UsersContext)

  const urlPrefix = 'https://tycho.pl/wp-content/themes/tycho/img/2019-icons/'

  const isFoldingSystem = isFoldingSystemCheck(product.product.wordpress_id)
  const isSlidingSystem = isSlidingSystemCheck(product.product.wordpress_id)
  const isDoubleSystem = isDoubleSystemCheck(product.product.wordpress_id)
  const isG100System = product.product.wordpress_id === 545
  const isG80System = product.product.wordpress_id === 555

  const hasTrack = isSlidingSystem || isFoldingSystem || isDoubleSystem || isG100System || isG80System
  const slidingSG = isSlidingSystem || isDoubleSystem || isG100System || isG80System

  const icon1 = () => {
    return (
      product.product.acf.image_1 ? {
        img: product.product.acf.image_1,
        qty: product.product.acf.quantity_1,
        name: product.product.acf.description_1,
      } : null
    )
  }
  const icon2 = () => {
    return (
      product.product.acf.image_2 ? {
        img: product.product.acf.image_2,
        qty: product.product.acf.quantity_2,
        name: product.product.acf.description_2,
      } : null
    )
  }
  const icon3 = () => {
    return (
      product.product.acf.image_3 ? {
        img: product.product.acf.image_3,
        qty: product.product.acf.quantity_3,
        name: product.product.acf.description_3,
      } : null
    )
  }
  const icon4 = () => {
    return (
      product.product.acf.image_4 ? {
        img: product.product.acf.image_4,
        qty: product.product.acf.quantity_4,
        name: product.product.acf.description_4,
      } : null
    )
  }
  const icon5 = () => {
    return (
      product.product.acf.image_5 ? {
        img: product.product.acf.image_5,
        qty: product.product.acf.quantity_5,
        name: product.product.acf.description_5,
      } : null
    )
  }
  const icon6 = () => {
    return (
      product.product.acf.image_6 ? {
        img: product.product.acf.image_6,
        qty: product.product.acf.quantity_6,
        name: product.product.acf.description_6,
      } : null
    )
  }
  const icon7 = () => {
    return (
      product.product.acf.image_7 ? {
        img: product.product.acf.image_7,
        qty: product.product.acf.quantity_7,
        name: product.product.acf.description_7,
      } : null
    )
  }
  const icon8 = () => {
    return (
      product.product.acf.image_8 ? {
        img: product.product.acf.image_8,
        qty: product.product.acf.quantity_8,
        name: product.product.acf.description_8,
      } : null
    )
  }

  const trackIcon = () => {
    const imgLink = () => {
      if (isFoldingSystem) {
        return 'Tycho_s-track.svg'
      } else if (isG80System) {
        return 'Tycho-b-track.svg'
      } else {
        return 'Tycho_s120-track.svg'
      }
    }
    return {
      img: urlPrefix + imgLink(),
      qty: 1,
      name: 'Prowadnica aluminiowa z otworami pod wkręty Ø 4 mm',
    }
  }

  const stopperIcon = () => ({
    img: urlPrefix + 'Tycho_s-clipstopper.svg',
    qty: isG100System ? 1 : isDoubleSystem ? 4 : 2,
    name: 'Stoper blokujący drzwi w krańcowych położeniach',
  })
  const bufferIcon = () => ({
    img: urlPrefix + 'Tycho_g100-buffer.svg',
    qty: 1,
    name: 'Stoper odbijający',
  })
  const g100MountingIcon = () => ({
    img: urlPrefix + 'Tycho_g100-mounting.svg',
    qty: 2,
    name: 'Mocowanie szkła',
  })
  const g100InsertsIcon = () => ({
    img: urlPrefix + 'Tycho_g100-inserts.svg',
    qty: 1,
    name: 'Wkładki elastomerowe do zacisków, dla różnej grubość szkła',
  })
  const g100HandleIcon = () => ({
    img: urlPrefix + 'Tycho_g100-handle.svg',
    qty: 2,
    name: 'Samoprzylepne uchwyty do drzwi z bezbarwnego tworzywa',
  })
  const g100GuideIcon = () => ({
    img: urlPrefix + 'Tycho_g100-guide.svg',
    qty: 1,
    name: 'Prowadnik dolny wykonany z bezbarwnego tworzywa',
  })
  const g100HexKeyIcon = () => ({
    img: urlPrefix + 'Tycho_hex-key.svg',
    qty: 1,
    name: 'Klucz imbusowy',
  })
  const g80MountingIcon = () => ({
    img: urlPrefix + 'Tycho_g80-mounting.svg',
    qty: 2,
    name: 'Mocowanie szkła',
  })
  const guideIcon = () => ({
    img: urlPrefix + 'Tycho_s-guide.svg',
    qty: isDoubleSystem ? 2 : 1,
    name: 'Prowadnik dolny wykonany z bezbarwnego tworzywa',
  })
  const topTrunnionIcon = () => ({
    img: urlPrefix + 'Tycho_f-top-trunnion.svg',
    qty: 1,
    name: 'Obsada czopu górnego',
  })
  const topPinIcon = () => ({
    img: urlPrefix + 'Tycho_f-top-pin.svg',
    qty: 1,
    name: 'Czop górny',
  })
  const bottomPinIcon = () => ({
    img: urlPrefix + 'Tycho_f-bottom-pin.svg',
    qty: 1,
    name: 'Czop dolny',
  })
  const bottomTrunionIcon = () => ({
    img: urlPrefix + 'Tycho_f-bottom-trunion.svg',
    qty: 1,
    name: 'Obsada czopu dolnego',
  })
  const hingeIcon = () => ({
    img: urlPrefix + 'Tycho_f-hinge.svg',
    qty: product.product.wordpress_id === 68 ? 3 : 4,
    name: 'Zawias',
  })
  const screwsIcon = () => {
    const imgLink = () => {
      if (product.product.wordpress_id === 68) {
        return 'Tycho_f-screws-f25.svg'
      } else if (product.product.wordpress_id === 74) {
        return 'Tycho_f-screws-f40.svg'
      } else {
        return 'Tycho_s-screws.svg'
      }
    }
    return {
      img: urlPrefix + imgLink(),
      qty: isDoubleSystem ? 2 : null,
      name: 'Wkręty montażowe',
    }
  }
  const damperIcon = (dmp, isS60_S100System) => ({
    img: isS60_S100System ? (urlPrefix + 'Tycho-damper-S.svg') : (urlPrefix + 'Tycho_damper-damper.svg'),
    qty: dmp * (synchro ? 1 : isDoubleSystem ? 2 : 1),
    name:
      'Domykacz wyhamowuje drzwi w skrajnym położeniu i łagodnie je domyka. Do drzwi o wadze do 80 kg',
  })

  const woodedFasciaBrktIcon1 = woodedFasciaBrkt => ({
    img: urlPrefix + 'Tycho_fascia-bracket-1.svg',
    qty: woodedFasciaBracket(1, trackLength, woodedFasciaBrkt).quantity,
    name: 'Uchwyt maskownicy',
  })

  const woodedFasciaBrktIcon2 = woodedFasciaBrkt => ({
    img: urlPrefix + 'Tycho_fascia-bracket-2.svg',
    qty: woodedFasciaBracket(1, trackLength, woodedFasciaBrkt).quantity,
    name: 'Uchwyt maskownicy',
  })

  const woodedFasciaBrktIconB = woodedFasciaBrkt => ({
    img: urlPrefix + 'Tycho_fascia-bracket-b.svg',
    qty: woodedFasciaBracket(1, trackLength, woodedFasciaBrkt).quantity,
    name: 'Uchwyt maskownicy',
  })

  const mountingWallIcon = (isG80System) => ({
    img: doorThickness === 1
      ? urlPrefix + 'Tycho_bracket-alu-45.svg'
      : urlPrefix + (isG80System ? 'Tycho-bracket-25-b.svg' : 'Tycho_bracket-alu-25.svg'),
    qty: doorThickness === 1
      ? wallBracket45(1, trackLength, mountingWall, doorThickness).quantity
      : wallBracket25(1, trackLength, mountingWall, doorThickness).quantity,
    name: doorThickness === 1 ? 'Klamra ścienna 45' : 'Klamra ścienna 25',
  })

  const aluFscIcon = () => ({
    img: urlPrefix + 'Tycho_alu-fascia-end.svg',
    qty: 1,
    name:
      'Maskownica aluminiowa z zaślepkami bocznymi i szczotką przeciwkurzową',
  })

  const aluFscSimpleIcon = () => ({
    img: urlPrefix + 'Tycho_fascia-simple.svg',
    qty: 1,
    name:
      'Maskownica aluminiowa płaska',
  })

  const spacerIcon = () => ({
    img: urlPrefix + 'Tycho_spacer.svg',
    qty: spacer(1, spcr, trackLength).quantity,
    name: 'Tuleja dystansowa',
  })

  const synchroIcon1 = () => ({
    img: urlPrefix + 'Tycho_2s-cable.svg',
    qty: 1,
    name: 'Linka stalowa – 8 m',
  })
  const synchroIcon2 = () => ({
    img: urlPrefix + 'Tycho_2s-pulley.svg',
    qty: 2,
    name: 'Stoper z rolką',
  })
  const synchroIcon3 = () => ({
    img: urlPrefix + 'Tycho_2s-fixing-plate-spring.svg',
    qty: 1,
    name: 'Wspornik montażowy ze sprężyną',
  })
  const synchroIcon4 = () => ({
    img: urlPrefix + 'Tycho_2s-angle-plate.svg',
    qty: 1,
    name: 'Łącznik',
  })

  const kitContentsGravity = ({ product, mountingWall, dmp }) => {
    const kitId = product?.product?.wordpress_id
    const isS60_S100System = (kitId === 59 || kitId === 65 || kitId === 10 || kitId === 27)

    return [
      hasTrack && trackIcon(),
      icon1(),
      icon2(),
      icon3(),
      icon4(),
      icon5(),
      icon6(),
      icon7(),
      icon8(),
      slidingSG && stopperIcon(),

      isG100System && bufferIcon(),
      isG100System && g100MountingIcon(),
      isG100System && g100InsertsIcon(),
      isG100System && g100HandleIcon(),
      isG100System && g100GuideIcon(),
      isG100System && g100HexKeyIcon(),

      isG80System && g80MountingIcon(),
      isG80System && g100HandleIcon(),
      isG80System && g100GuideIcon(),

      isDoubleSystem && guideIcon(),
      isDoubleSystem && screwsIcon(),

      isSlidingSystem && guideIcon(),
      isSlidingSystem && screwsIcon(),

      isFoldingSystem && topTrunnionIcon(),
      isFoldingSystem && topPinIcon(),
      isFoldingSystem && bottomPinIcon(),
      isFoldingSystem && bottomTrunionIcon(),
      isFoldingSystem && hingeIcon(),

      mountingWall ? mountingWallIcon(isG80System) : null,

      isFoldingSystem && !isG80System && woodedFasciaBrkt ? woodedFasciaBrktIcon1(woodedFasciaBrkt) : null,
      !isFoldingSystem && !isG80System && woodedFasciaBrkt ? woodedFasciaBrktIcon2(woodedFasciaBrkt) : null,
      !isFoldingSystem && isG80System && woodedFasciaBrkt ? woodedFasciaBrktIconB(woodedFasciaBrkt) : null,

      aluFsc ? aluFscIcon() : null,
      aluFscSimple ? aluFscSimpleIcon() : null,
      spcr ? spacerIcon() : null,
      synchro ? synchroIcon1() : null,
      synchro ? synchroIcon2() : null,
      synchro ? synchroIcon3() : null,
      synchro ? synchroIcon4() : null,
      dmp ? damperIcon(dmp, isS60_S100System) : null,
    ]
  }

  SwiperCore.use([Navigation, Pagination]);

  const { t } = useTranslation()

  return (
    <>
      {product.product.tags.every(e => e.name !== 'gravity') && (
        <KitContentsSimple acf={product.product.acf} />
      )}

      <CustomSwiper >
        {product.product.tags.some(e => e.name === 'gravity') &&
          kitContentsGravity({
            product,
            trackLength,
            mountingWall,
            doorThickness,
            dmp,
          }).map(
            (item, index) =>
              item && (
                <SwiperSlide key={item.name + index}>
                  <Element key={item.name + index}>
                    <img src={item.img} alt={item.name} />
                    {item.qty && '×' + item.qty} <br />
                    {t(`${item.name}`)}

                  </Element>
                </SwiperSlide>
              )
          )}
      </CustomSwiper>

    </>
  )
}

export default KitContents
