import React from 'react'
import styled from 'styled-components'
import {  breakpoints } from '../../utils/theme'

const StyledLink = styled.a`
  display: table;
  margin-bottom: 24px;
  transition: opacity .2s;

  &:hover {
    opacity: 0.6
  }

  @media (max-width: ${breakpoints.mobile}px) {
    margin-bottom: 16px;
  }

`

export default function SimpleLink({url, title }) {
  return <StyledLink target="_blank" rel="noopener noreferrer" href={url}> {title} </StyledLink>
}
