import React from 'react'
import ProductCardData from '../../molecules/ProductCardData'
import ProductGrid from '../../molecules/ProductGrid'

const matchUpSellsById = (edges, ids) => edges.node.wordpress_id === ids

const YouMayAlsoLike = ({ product, data }) => (
  <>
    <ProductGrid>
      {product.node.upsell_ids
        .slice(0, 4)
        .map((id) => (
          <ProductCardData
            key={id}
            product={
              data.allWcProducts.edges.filter(edges =>
                matchUpSellsById(edges, id)
              )[0]?.node
            }
          />
        ))}
    </ProductGrid>
  </>
)

export default YouMayAlsoLike
