import React from 'react'
import styled from 'styled-components'
import { tychoNavy, typoLightGray } from '../../utils/theme'
import RegularTypo from '../../typography/RegularTypo'

const StyledRadioButton = styled.div`
  height: 49px;
  padding: 15px; 
  transition: all 0.3s;
  ${({ selected }) =>
    !selected ? `color: ${typoLightGray}` : `color: inherit`};
  ${({ selected }) =>
    selected ? `border-bottom: 1px solid ${tychoNavy}` : null};
  :hover {
    cursor: pointer;
    opacity: 0.87;
  }

  > div { 
    line-height: initial
  }
`

const RadioButton = ({ text, selected, onClick }) => {
  return (
    <StyledRadioButton selected={selected} onClick={({ target }) => onClick(target)}>
      <RegularTypo oneline text={text} />
    </StyledRadioButton>
  )
}

export default RadioButton