import React from 'react'
import styled from 'styled-components'
import { breakpoints } from '../../utils/theme'

const StyledElement = styled.div`
  width: 80%;
  margin: 0 auto;
  display: block;
  white-space: normal;
  font-size: 16px;
  font-weight: 400;
  text-align: center;

  img {
    margin: 6px;
  }
 
  @media (max-width: ${breakpoints.mobile}px) {
    font-size: 14px;
    width: 100%;
    text-align: left;
  }
`

export default function Element({ children }) {
  return <StyledElement>{children}</StyledElement>
}
