import React, { useEffect } from 'react'
import smoothscrollPolyfill from 'smoothscroll-polyfill'
import styled from 'styled-components'
import { tychoNavy, typoLightGray, } from '../../utils/theme'


const AnchorButton = styled.a`
  height: 48px;  
  padding: 15px 15px 16px; 
  transition: all 0.3s;
  text-decoration: none; 
  line-height: initial; 
  font-size: 16px;

  ${({ active }) =>
        !active ? `color: ${typoLightGray}` : `color: inherit`};
  ${({ active }) =>
        active ? `border-bottom: 1px solid ${tychoNavy}` : `border-bottom: 1px solid transparent`};

  :hover {
    opacity: 0.87;
  }

  svg {
      fill: ${({ active }) => (active ? 'rgba(0,0,0,0.5)' : typoLightGray)};
      display: block; 
  }
 
`


const AnchorLink = ({ offset, active, ...rest }) => {

    const smoothScroll = (e) => {
        e.preventDefault()
        const id = e.currentTarget.getAttribute('href').slice(1)
        const $anchor = document.getElementById(id);
        const offsetTop = $anchor.getBoundingClientRect().top + window.pageYOffset;
        window.scroll({
            top: offsetTop - offset || 0,
            behavior: 'smooth'
        })
    }

    useEffect(() => {
        smoothscrollPolyfill.polyfill()
    }, [])

    return (
        <AnchorButton
            onClick={smoothScroll}
            active={active}
            {...rest}
        />
    )
}

export default AnchorLink