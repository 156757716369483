import React from 'react'
import styled from 'styled-components'
import RegularTypo from '../../typography/RegularTypo'
import RadioButton from '../../atoms/RadioButton'
import Swtch from '../../atoms/Swtch'
import { borderGray } from '../../utils/theme'
import { useTranslation } from 'react-i18next'

const ChoiceWrapper = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${borderGray};
  justify-content: space-between;
`
const ButtonsWrapper = styled.div`
  display: flex;
`

export default function Choice({
  label,
  enumeration,
  swtch,
  value,
  setValue,
}) {

  // const [swiper, setSwiper] = React.useState(null);
  // useEffect(() => {
  //   const mySwiper = document.querySelector(".content-kit-swiper");
  //   mySwiper && setSwiper(mySwiper.swiper);
  // }, [])

  const { t } = useTranslation()

  return (
    <ChoiceWrapper>
      <RegularTypo oneline text={label} />
      {enumeration && (
        <ButtonsWrapper>
          {enumeration.map((item, index) => (
            <RadioButton
              text={t(item)}
              key={item}
              selected={value === index}
              radioName={item}
              onClick={() => {
                setValue(index)
                // swiper && swiper.slideTo(99999)
              }}
            />
          ))}
        </ButtonsWrapper>
      )}
      {swtch && <Swtch isOn={value} onClick={() => setValue(!value)} />}
    </ChoiceWrapper>
  )
}
