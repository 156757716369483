import React from 'react'
import Element from './element'
import { SwiperSlide } from 'swiper/react'
import CustomSwiper from './CustomSwiper'
import { useTranslation } from 'react-i18next'

export default function KitContentsSimple({ acf }) {
  const { t } = useTranslation()

  return (
    <CustomSwiper >
      {acf.description_1 &&
        <SwiperSlide>
          <Element>
            <img src={acf.image_1} alt={acf.description_1} />
            ×{acf.quantity_1} <br />
            {t(`${acf.description_1}`)}
          </Element>
        </SwiperSlide>
      }
      {acf.description_2 && <SwiperSlide>
        <Element>
          <img src={acf.image_2} alt={acf.description_2} />
          ×{acf.quantity_2} <br />
          {t(`${acf.description_2}`)}

        </Element></SwiperSlide>
      }
      {acf.description_3 && <SwiperSlide>
        <Element>
          <img src={acf.image_3} alt={acf.description_3} />
          ×{acf.quantity_3} <br />
          {t(`${acf.description_3}`)}

        </Element>
      </SwiperSlide>
      }
      {acf.description_4 && <SwiperSlide>
        <Element>
          <img src={acf.image_4} alt={acf.description_4} />
          ×{acf.quantity_4} <br />
          {t(`${acf.description_4}`)}

        </Element>
      </SwiperSlide>
      }
      {acf.description_5 && <SwiperSlide>
        <Element>
          <img src={acf.image_5} alt={acf.description_5} />
          ×{acf.quantity_5} <br />
          {t(`${acf.description_5}`)}

        </Element>
      </SwiperSlide>
      }
      {acf.description_6 && <SwiperSlide>
        <Element>
          <img src={acf.image_6} alt={acf.description_6} />
          ×{acf.quantity_6} <br />
          {t(`${acf.description_6}`)}

        </Element>
      </SwiperSlide>
      }
      {acf.description_7 && <SwiperSlide>
        <Element>
          <img src={acf.image_7} alt={acf.description_7} />
          ×{acf.quantity_7} <br />
          {t(`${acf.description_7}`)}
        </Element>
      </SwiperSlide>
      }
      {acf.description_8 && <SwiperSlide>
        <Element>
          <img src={acf.image_8} alt={acf.description_8} />
          ×{acf.quantity_8} <br />
          {t(`${acf.description_8}`)}
        </Element>
      </SwiperSlide>
      }
    </CustomSwiper>
  )
}
