import React from 'react'
import { breakpoints } from '../../utils/theme'
import styled from 'styled-components'

const StyledText = styled.div`
  font-size: 33.18px;
  line-height: 1.16666666667;
  font-weight: 500;

  @media (max-width: ${breakpoints.mobile + 'px'}) {
    font-size: 29.03px
  }

`

const Text = ({ id, text, style }) => {

  return (
    <StyledText id={id} style={{ ...style }}>
      {text}
    </StyledText>
  )
}

export default Text