import React from 'react'
import Configurator from '../Configurator'
import { breakpoints } from '../../utils/theme'
import Text from '../../atoms/Text'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'

const ProdDesc = styled.div`
  margin-top: 18px;
  line-height: 1.5;
  font-size: 16px;

  ul {
    margin: 0;
  }

  li {
    margin-bottom: 0;
    list-style: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  li:not(:nth-child(-n + 3)) {
    display: none;
  }
 
  @media (max-width: ${breakpoints.mobile}px) {
    margin-top: 16px;
    font-size: 14px;
  }

`

const ConfiguratorWrapper = ({ product, className }) => {
  const { t } = useTranslation();
  var regexHtmlTags = /(<([^>]+)>)/ig
  const description = product.node.short_description.replace(regexHtmlTags, "").trim()

  return (
    <div className={className}>
      <Text id="product-name" text={t(`product::${product.node.name}`)} />  
        <ProdDesc>
          <ReactMarkdown className="section_content" children={t(`${description}`)} />
        </ProdDesc>
      <Configurator product={product} />
    </div>
  ) 
}

export default ConfiguratorWrapper
