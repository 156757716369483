import React from 'react'
import styled from 'styled-components'
import { borderGray, breakpoints } from '../../utils/theme'

const StyledLine = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  background: ${borderGray};

  @media (max-width: ${breakpoints.mobile}px) {
    display: none;
  }

`

const Line = () => <StyledLine />

export default Line 