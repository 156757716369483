import React from 'react'
import MaterialSlider from '@material-ui/core/Slider'
import { withStyles } from '@material-ui/core/styles'
import { tychoNavy } from '../../utils/theme'

const TychoSlider = withStyles({
  root: {
    color: tychoNavy,
  },
})(MaterialSlider)



const Slider = ({ marks, minValue, maxValue, value, setValue }) => {
  return (
    <TychoSlider
      value={typeof value === 'number' ? value : 0}
      onChange={(event, val) => setValue(val)}
      min={marks ? marks[0].value : minValue}
      max={maxValue}
      aria-labelledby="input-slider"
      step={marks ? null : 1}
      marks={marks}
    />
  )
}

export default Slider