import React from 'react'
import styled from 'styled-components'
import { breakpoints } from '../../utils/theme'

const StyledElement = styled.div`
  width: 140px;
  padding: 0 16px;
  display: inline-block;
  vertical-align: top;
  white-space: normal;
  font-size: 16px;
  text-align: center;

  img {
    margin: 6px;
  }
 
  @media (max-width: ${breakpoints.mobile}px) {
    font-size: 14px;
  }
`

export default function Element({ children }) {
  return <StyledElement>{children}</StyledElement>
}
