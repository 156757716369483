import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { tychoNavy, borderGray, } from '../../utils/theme'

const Swtch = styled.div`
  width: ${({ labelless }) => labelless ? '48px' : '60px'};
  height: 24px; 
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  ${({ isOn }) =>
    `border: ${isOn ? `1px solid` + tychoNavy : `1px solid` + borderGray}`};
  ${({ isOn }) => `color: ${isOn ? `white` : tychoNavy}`};
  ${({ isOn, labelless }) => `background: ${isOn ? tychoNavy : labelless ? borderGray : 'white'}`};


  .nipple {
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    ${({ isOn }) => `left: ${!isOn && `-1px`}`};
    ${({ isOn }) => `right: ${isOn && `-1px`}`};
    ${({ isOn }) =>
    `border: ${isOn ? `1px solid` + tychoNavy : `1px solid` + borderGray}`};
    background: white;
    
  }
`

const Label = styled.div`
  font-size: 10px;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  margin-left: ${({ isOn }) => (isOn ? '-18px' : '18px')};
`

export default function Switch({ isOn, onClick, labelless }) {

  const { t } = useTranslation()

  return (
    <Swtch labelless={labelless} isOn={isOn} onClick={({ target }) => onClick(target)}>
      <div className="nipple" />
      <Label isOn={isOn}> 
        { !labelless && (isOn ? t('Tak') : t('Nie'))}
       </Label>
    </Swtch>
  )
}
