import React from 'react'
import Element from './element'
import { SwiperSlide } from 'swiper/react'
import CustomSwiper from './CustomSwiper'
import { useTranslation } from 'react-i18next'

const TechDrawings = product => {
 
    const { t } = useTranslation()
  
    return (
      <CustomSwiper >
        {product.product.acf.tech_image_1 &&
          <SwiperSlide>
            <Element>
              <img src={product.product.acf.tech_image_1} alt={product.product.acf.tech_description_1} />
              {t(`Rys.`) + ' 1. '}
              {t(`${product.product.acf.tech_description_1}`)}
            </Element>
          </SwiperSlide>
        }
        {product.product.acf.tech_description_2 && <SwiperSlide>
          <Element>
            <img src={product.product.acf.tech_image_2} alt={product.product.acf.tech_description_2} />
            {t(`Rys.`) + ' 2. '}
            {t(`${product.product.acf.tech_description_2}`)}
          </Element></SwiperSlide>
        }
        {product.product.acf.tech_description_3 && <SwiperSlide>
          <Element>
            <img src={product.product.acf.tech_image_3} alt={product.product.acf.tech_description_3} />
            {t(`Rys.`) + ' 3. '}
            {t(`${product.product.acf.tech_description_3}`)}
  
          </Element>
        </SwiperSlide>
        }
        {product.product.acf.tech_description_4 && <SwiperSlide>
          <Element>
            <img src={product.product.acf.tech_image_4} alt={product.product.acf.tech_description_4} />
            {t(`Rys.`) + ' 4. '}
            {t(`${product.product.acf.tech_description_4}`)}
  
          </Element>
        </SwiperSlide>
        }
        {product.product.acf.tech_description_5 && <SwiperSlide>
          <Element>
            <img src={product.product.acf.tech_image_5} alt={product.product.acf.tech_description_5} />
            {t(`Rys.`) + ' 5. '}
            {t(`${product.product.acf.tech_description_5}`)}
  
          </Element>
        </SwiperSlide>
        }
        {product.product.acf.tech_description_6 && <SwiperSlide>
          <Element>
            <img src={product.product.acf.tech_image_6} alt={product.product.acf.tech_description_6} />
            {t(`Rys.`) + ' 6. '}
            {t(`${product.product.acf.tech_description_6}`)}
  
          </Element>
        </SwiperSlide>
        }
        {product.product.acf.tech_description_7 && <SwiperSlide>
          <Element>
            <img src={product.product.acf.tech_image_7} alt={product.product.acf.tech_description_7} />
            {t(`Rys.`) + ' 7. '}
            {t(`${product.product.acf.tech_description_7}`)}
          </Element>
        </SwiperSlide>
        }
        {product.product.acf.tech_description_8 && <SwiperSlide>
          <Element>
            <img src={product.product.acf.tech_image_8} alt={product.product.acf.tech_description_8} />
            {t(`Rys.`) + ' 8. '}
            {t(`${product.product.acf.tech_description_8}`)}
          </Element>
        </SwiperSlide>
        }
      </CustomSwiper>
    )
  }
  
 

export default TechDrawings
